$(function($) {

    $('.carousel').carousel('cycle');

    $("#list-carousel,#list2-carousel").swiperight(function() {
        $(this).carousel('prev');
    });
    $("#list-carousel,#list2-carousel").swipeleft(function() {
        $(this).carousel('next');
    });

    var map = {};
    $(document).ready(function() {
        $('.map').each(function (index, obj) {
            var i = index+1;
            console.log($(obj).attr('id'));
            map[i] = new GMaps({
                div: '#'+$(obj).attr('id'),
                lat: 47.473495,
                lng: 19.254053,
                zoom: 11,
                scrollwheel: false,
                navigationControl: false,
                mapTypeControl: false,
                scaleControl: false,
                draggable: false,
                mapTypeId: google.maps.MapTypeId.ROADMAP
            })
            map[i].addMarker({
                lat: 47.473495,
                lng: 19.254053,
                title: '1173 Bp. Ferihegyi út 166.',

            })
            map[i].addControl({
                position: 'top_right',
                content: 'Utvonalterv',
                style: {
                    margin: '5px',
                    padding: '1px 6px',
                    border: 'solid 1px #717B87',
                    background: '#fff'
                },
                events: {
                    click: function () {
                        var url = "https://www.google.com/maps/dir//" + map.getCenter().lat() + "," + map.getCenter().lng() + "/@" + map.getCenter().lat() + "," + map.getCenter().lng() + ",12z/data=!4m2!4m1!3e0";
                        window.open(url, '_blank');
                    }
                }
            });
        });
    });
});